<template>
  <v-container fluid>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-timeline
        dense
        v-if="historico.length"
      >
        <v-timeline-item
          v-for="(alteracao, i) in historico"
          :key="i"
          color="cyan"
          small
        >
          <v-card class="elevation-2">
            <v-card-title class="text-h5">
              Alteração feita por {{ alteracao.usuario_alteracao }} em
              {{ alteracao.data_hora_alteracao | parseToDateTimeBR }}
            </v-card-title>
            <v-divider />
            <v-card-text>
              Variável "{{ alteracao.descricao_variavel }}" da BDGD
              {{ alteracao.ano_bdgd }} alterada para o valor
              {{ alteracao.valor_variavel | parseNumberToFloatBR }}.
              <div v-if="alteracao.observacao">
                <v-divider class="mt-4 mb-4" />
                {{ alteracao.observacao }}
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
  </v-container>
</template>

<script>
import IqBdgdService from '@/services/IqBdgdService';
import iqBdgdMixins from './iqBdgdMixins';

export default {
  name: 'StatusIndicadorIcoTimeline',
  mixins: [iqBdgdMixins],
  props: {
    indicador: {
      type: String,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.getHistoricoStatusPorIndicador();
  },
  data: () => ({
    loading: false,
    historico: []
  }),
  methods: {
    getHistoricoStatusPorIndicador() {
      this.loading = true;
      IqBdgdService.getHistoricoStatusPorIndicador(
        this.bdgdVersao.id,
        this.indicador
      )
        .then((response) => {
          this.historico = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar o histórico das variáveis do indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
