<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(iptb)"
      :title="`IPTB: ${iqBdgdSiglas['IPTB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iptb)"
        class="darken-4"
      >
        {{ getNotaFormatada(iptb) | parseNumberToFloatBR }}
      </v-avatar>
      IPTB
    </v-chip>
    = Erro Etapa Perdas
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaIPTB',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    iptb: {
      default: 0
    }
  }
};
</script>
