<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(consist)"
      :title="`CONSIST: ${iqBdgdSiglas['CONSIST']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(consist)"
        class="darken-4"
      >
        {{ getNotaFormatada(consist) | parseNumberToFloatBR }}
      </v-avatar>
      CONSIST
    </v-chip>
    = MÉDIA (
    <v-chip
      class="mr-2"
      :color="getCorPorNota(idb)"
      :title="`IDB: ${iqBdgdSiglas['IDB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(idb)"
        class="darken-4"
      >
        {{ getNotaFormatada(idb) | parseNumberToFloatBR }}
      </v-avatar>
      IDB
    </v-chip>
    ;
    <v-chip
      class="mr-2"
      :color="getCorPorNota(iesp)"
      :title="`IESP: ${iqBdgdSiglas['IESP']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iesp)"
        class="darken-4"
      >
        {{ getNotaFormatada(iesp) | parseNumberToFloatBR }}
      </v-avatar>
      IESP
    </v-chip>
    ;
    <v-chip
      class="mr-2"
      :color="getCorPorNota(icb)"
      :title="`ICB: ${iqBdgdSiglas['ICB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(icb)"
        class="darken-4"
      >
        {{ getNotaFormatada(icb) | parseNumberToFloatBR }}
      </v-avatar>
      ICB
    </v-chip>
    ;
    <v-chip
      class="mr-2"
      :color="getCorPorNota(ico)"
      :title="`ICO: ${iqBdgdSiglas['ICO']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(ico)"
        class="darken-4"
      >
        {{ getNotaFormatada(ico) | parseNumberToFloatBR }}
      </v-avatar>
      ICO
    </v-chip>
    ;
    <v-chip
      class="mr-2"
      :color="getCorPorNota(ieb)"
      :title="`IEB: ${iqBdgdSiglas['IEB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(ieb)"
        class="darken-4"
      >
        {{ getNotaFormatada(ieb) | parseNumberToFloatBR }}
      </v-avatar>
      IEB
    </v-chip>
    ;
    <v-chip
      class="mr-2"
      :color="getCorPorNota(iptb)"
      :title="`IPTB: ${iqBdgdSiglas['IPTB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iptb)"
        class="darken-4"
      >
        {{ getNotaFormatada(iptb) | parseNumberToFloatBR }}
      </v-avatar>
      IPTB
    </v-chip>
    )
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaConsist',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    consist: {
      default: 0
    },
    idb: {
      default: 0
    },
    iesp: {
      default: 0
    },
    icb: {
      default: 0
    },
    ico: {
      default: 0
    },
    ieb: {
      default: 0
    },
    iptb: {
      default: 0
    }
  }
};
</script>
