<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(ico)"
      :title="`ICO: ${iqBdgdSiglas['ICO']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(ico)"
        class="darken-4"
      >
        {{ getNotaFormatada(ico) | parseNumberToFloatBR }}
      </v-avatar>
      ICO
    </v-chip>
    = MÉDIA (
    <v-chip
      class="mr-2"
      :color="getCorPorNota(icesc)"
      :title="`ICESC: ${iqBdgdSiglas['ICESC']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(icesc)"
        class="darken-4"
      >
        {{ getNotaFormatada(icesc) | parseNumberToFloatBR }}
      </v-avatar>
      ICESC
    </v-chip>
    ;
    <v-chip
      class="ml-2"
      :color="getCorPorNota(iccpl)"
      :title="`ICCPL: ${iqBdgdSiglas['ICCPL']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iccpl)"
        class="darken-4"
      >
        {{ getNotaFormatada(iccpl) | parseNumberToFloatBR }}
      </v-avatar>
      ICCPL
    </v-chip>
    )
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaICO',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    ico: {
      default: 0
    },
    icesc: {
      default: 0
    },
    iccpl: {
      default: 0
    }
  }
};
</script>
