var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
    {
      text: 'Etapa NVAL',
      value: 'etapa_nval'
    },
    {
      text: 'Erros Críticos',
      value: 'erros_criticos',
      align: 'end'
    },
    {
      text: 'Não Críticos',
      value: 'erros_nao_criticos',
      align: 'end'
    },
    {
      text: 'Exceções',
      value: 'excecoes',
      align: 'end'
    },
    {
      text: 'Análises Futuras',
      value: 'analises_futuras',
      align: 'end'
    },
    {
      text: 'Indicador',
      value: 'indicador',
      align: 'end'
    },
    {
      text: 'Nota',
      value: 'nota',
      align: 'center'
    }
  ],"items":_vm.iespAnalitico,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.etapa_nval",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" Etapa "+_vm._s(_vm._f("addLeadingZero")(item.etapa_nval))+" - "+_vm._s(item.sigla_teste)+" ")])]}},{key:"item.erros_criticos",fn:function(ref){
  var item = ref.item;
return [(_vm.editavel && item.sigla_teste === 'IPOS')?_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_c('v-edit-dialog',{attrs:{"large":"","cancel-text":"Cancelar","save-text":"Salvar"},on:{"open":function($event){_vm.valorAtualizado = item.erros_criticos},"close":function($event){_vm.valorAtualizado = null},"save":function () { return _vm.salvarNumeroErros('ERRO_CRITICO'); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 mb-4 text-h6"},[_vm._v("Erros Críticos")]),_c('v-text-field',{attrs:{"label":"Atualizar valor","type":"number","step":1,"min":0,"autofocus":""},model:{value:(_vm.valorAtualizado),callback:function ($$v) {_vm.valorAtualizado=$$v},expression:"valorAtualizado"}}),_c('v-textarea',{attrs:{"label":"Observação","rows":"2","no-resize":"","maxlength":"255"},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(_vm._f("parseNumberToIntegerBR")(item.erros_criticos)))])])],1):_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.erros_criticos))+" ")])]}},{key:"item.erros_nao_criticos",fn:function(ref){
  var item = ref.item;
return [(_vm.editavel && item.sigla_teste === 'IPOS')?_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_c('v-edit-dialog',{attrs:{"large":"","cancel-text":"Cancelar","save-text":"Salvar"},on:{"open":function($event){_vm.valorAtualizado = item.erros_nao_criticos},"close":function($event){_vm.valorAtualizado = null},"save":function () { return _vm.salvarNumeroErros('ERRO_NAO_CRITICO'); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 mb-4 text-h6"},[_vm._v("Não Críticos")]),_c('v-text-field',{attrs:{"label":"Atualizar valor","type":"number","step":1,"min":0,"autofocus":""},model:{value:(_vm.valorAtualizado),callback:function ($$v) {_vm.valorAtualizado=$$v},expression:"valorAtualizado"}}),_c('v-textarea',{attrs:{"label":"Observação","rows":"2","no-resize":"","maxlength":"255"},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(_vm._f("parseNumberToIntegerBR")(item.erros_nao_criticos)))])])],1):_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.erros_nao_criticos))+" ")])]}},{key:"item.excecoes",fn:function(ref){
  var item = ref.item;
return [(_vm.editavel && item.sigla_teste === 'IPOS')?_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_c('v-edit-dialog',{attrs:{"large":"","cancel-text":"Cancelar","save-text":"Salvar"},on:{"open":function($event){_vm.valorAtualizado = item.excecoes},"close":function($event){_vm.valorAtualizado = null},"save":function () { return _vm.salvarNumeroErros('EXCECAO'); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 mb-4 text-h6"},[_vm._v("Exceções")]),_c('v-text-field',{attrs:{"label":"Atualizar valor","type":"number","step":1,"min":0,"autofocus":""},model:{value:(_vm.valorAtualizado),callback:function ($$v) {_vm.valorAtualizado=$$v},expression:"valorAtualizado"}}),_c('v-textarea',{attrs:{"label":"Observação","rows":"2","no-resize":"","maxlength":"255"},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(_vm._f("parseNumberToIntegerBR")(item.excecoes)))])])],1):_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.excecoes))+" ")])]}},{key:"item.analises_futuras",fn:function(ref){
  var item = ref.item;
return [(_vm.editavel && item.sigla_teste === 'IPOS')?_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_c('v-edit-dialog',{attrs:{"large":"","cancel-text":"Cancelar","save-text":"Salvar"},on:{"open":function($event){_vm.valorAtualizado = item.analises_futuras},"close":function($event){_vm.valorAtualizado = null},"save":function () { return _vm.salvarNumeroErros('ANALISE_FUTURA'); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 mb-4 text-h6"},[_vm._v("Análises Futuras")]),_c('v-text-field',{attrs:{"label":"Atualizar valor","type":"number","step":1,"min":0,"autofocus":""},model:{value:(_vm.valorAtualizado),callback:function ($$v) {_vm.valorAtualizado=$$v},expression:"valorAtualizado"}}),_c('v-textarea',{attrs:{"label":"Observação","rows":"2","no-resize":"","maxlength":"255"},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(_vm._f("parseNumberToIntegerBR")(item.analises_futuras)))])])],1):_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.analises_futuras))+" ")])]}},{key:"item.indicador",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(item.indicador)+" ")])]}},{key:"item.nota",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(item.nota)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }