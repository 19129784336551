<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(iesp)"
      :title="`IESP: ${iqBdgdSiglas['IESP']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iesp)"
        class="darken-4"
      >
        {{ getNotaFormatada(iesp) | parseNumberToFloatBR }}
      </v-avatar>
      IESP
    </v-chip>
    = MIN (
    <v-chip
      class="mr-2"
      :color="getCorPorNota(itop)"
      :title="`ITOP: ${iqBdgdSiglas['ITOP']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(itop)"
        class="darken-4"
      >
        {{ getNotaFormatada(itop) | parseNumberToFloatBR }}
      </v-avatar>
      ITOP
    </v-chip>
    ;
    <v-chip
      class="ml-2"
      :color="getCorPorNota(ipos)"
      :title="`IPOS: ${iqBdgdSiglas['IPOS']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(ipos)"
        class="darken-4"
      >
        {{ getNotaFormatada(ipos) | parseNumberToFloatBR }}
      </v-avatar>
      IPOS
    </v-chip>
    )
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaIESP',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    iesp: {
      default: 0
    },
    itop: {
      default: 0
    },
    ipos: {
      default: 0
    }
  }
};
</script>
