<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-database-eye-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Painel IQ_BDGD :: BDGD
              <v-progress-circular
                v-if="loadingBdgd"
                size="20"
                indeterminate
                color="rgba(0, 0, 0, 0.87)"
              />
              <span v-else>
                {{ bdgd.ordinaria ? 'Ordinária' : '' }}
                {{ bdgd.data_registro | formatToMonth }} v{{ bdgd.versao }}
              </span>
            </div>
          </template>
          <v-progress-circular
            class="mt-10"
            v-if="loadingBdgd || loadingIqBdgdNotas || loadingIqBdgdSiglas"
            size="20"
            indeterminate
            color="rgba(0, 0, 0, 0.87)"
          />
          <v-row v-else>
            <v-col cols="4">
              <v-card tile>
                <v-subheader>
                  IQ_BDGD :: {{ iqBdgdSiglas['IQ_BDGD'] }}
                </v-subheader>
                <v-divider />
                <v-card-text>
                  <div
                    class="d-flex justify-center align-center"
                    style="height: 100px"
                  >
                    <h1
                      class="texto-grande"
                      :style="{ color: getCorPorNota(notas.iqbdgd) }"
                    >
                      {{
                        getNotaFormatada(notas.iqbdgd) | parseNumberToFloatBR
                      }}
                    </h1>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card tile>
                <v-subheader>
                  CONSIST :: {{ iqBdgdSiglas['CONSIST'] }}
                </v-subheader>
                <v-divider />
                <v-card-text>
                  <div
                    class="d-flex justify-center align-center"
                    style="height: 100px"
                  >
                    <h1
                      class="texto-grande"
                      :style="{ color: getCorPorNota(notas.consist) }"
                    >
                      {{
                        getNotaFormatada(notas.consist) | parseNumberToFloatBR
                      }}
                    </h1>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card tile>
                <v-subheader> ICP :: {{ iqBdgdSiglas['ICP'] }} </v-subheader>
                <v-divider />
                <v-card-text>
                  <div
                    class="d-flex justify-center align-center"
                    style="height: 100px"
                  >
                    <h1
                      class="texto-grande"
                      :style="{ color: getCorPorNota(notas.icp) }"
                    >
                      {{ getNotaFormatada(notas.icp) | parseNumberToFloatBR }}
                    </h1>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-tabs
                centered
                v-model="tabsLevel1"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                  href="#tab-memoria-calculos"
                  class="primary--text"
                >
                  Memória de Cálculos
                </v-tab>
                <v-tab
                  href="#tab-idb"
                  class="primary--text"
                >
                  IDB
                </v-tab>
                <v-tab
                  href="#tab-iesp"
                  class="primary--text"
                >
                  IESP
                </v-tab>
                <v-tab
                  href="#tab-icb"
                  class="primary--text"
                >
                  ICB
                </v-tab>
                <v-tab
                  href="#tab-ieb"
                  class="primary--text"
                >
                  IEB
                </v-tab>
                <v-tab
                  href="#tab-iptb"
                  class="primary--text"
                >
                  IPTB
                </v-tab>
                <v-tab
                  href="#tab-icp"
                  class="primary--text"
                >
                  ICP
                </v-tab>
                <v-tab
                  href="#tab-ico"
                  class="primary--text"
                >
                  ICO
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabsLevel1">
                <v-tab-item value="tab-memoria-calculos">
                  <v-progress-circular
                    v-if="
                      loadingBdgd || loadingIqBdgdNotas || loadingIqBdgdSiglas
                    "
                    size="20"
                    indeterminate
                    color="rgba(0, 0, 0, 0.87)"
                  />
                  <span v-else>
                    <v-card tile>
                      <v-subheader>
                        1. IQ_BDGD :: {{ iqBdgdSiglas['IQ_BDGD'] }}
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <formula-iqbdgd
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :iqbdgd="notas.iqbdgd"
                          :consist="notas.consist"
                          :icp="notas.icp"
                        />
                      </v-card-text>
                    </v-card>
                    <v-card tile>
                      <v-subheader>
                        1.1 CONSIST :: {{ iqBdgdSiglas['CONSIST'] }}
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <formula-consist
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :consist="notas.consist"
                          :idb="notas.idb"
                          :iesp="notas.iesp"
                          :icb="notas.icb"
                          :ico="notas.ico"
                          :ieb="notas.ieb"
                          :iptb="notas.iptb"
                        />
                        <formula-idb
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :idb="notas.idb"
                          :ivb="notas.ivb"
                          :irlc="notas.irlc"
                          :iconj="notas.iconj"
                          :imun="notas.imun"
                          :iene="notas.iene"
                          :iext="notas.iext"
                          :isev="notas.isev"
                          :idda="notas.idda"
                        />
                        <formula-iesp
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :iesp="notas.iesp"
                          :itop="notas.itop"
                          :ipos="notas.ipos"
                        />
                        <formula-icb
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :icb="notas.icb"
                          :imcp="notas.imcp"
                          :iacc="notas.iacc"
                        />
                        <formula-ico
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :ico="notas.ico"
                          :icesc="notas.icesc"
                          :iccpl="notas.iccpl"
                        />
                        <formula-ieb
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :ieb="notas.ieb"
                        />
                        <formula-iptb
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :iptb="notas.iptb"
                        />
                      </v-card-text>
                    </v-card>
                    <v-card tile>
                      <v-subheader>
                        1.2 ICP :: {{ iqBdgdSiglas['ICP'] }}
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <formula-icp
                          :iqBdgdSiglas="iqBdgdSiglas"
                          :icp="notas.icp"
                          :ient="notas.ient"
                          :iana="notas.iana"
                          :iprot="notas.iprot"
                        />
                      </v-card-text>
                    </v-card>
                  </span>
                </v-tab-item>
                <v-tab-item value="tab-idb">
                  <v-card tile>
                    <v-subheader>
                      Fórmula ANEEL para cálculo do IDB ::
                      {{ iqBdgdSiglas['IDB'] }}
                    </v-subheader>
                    <v-divider />
                    <v-card-text>
                      <formula-idb
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :idb="notas.idb"
                        :ivb="notas.ivb"
                        :irlc="notas.irlc"
                        :iconj="notas.iconj"
                        :imun="notas.imun"
                        :iene="notas.iene"
                        :iext="notas.iext"
                        :isev="notas.isev"
                        :idda="notas.idda"
                      />
                    </v-card-text>
                  </v-card>
                  <tabela-idb-analitico
                    class="mb-5"
                    :iqBdgdSiglas="iqBdgdSiglas"
                    :bdgdVersao="bdgd"
                  />
                </v-tab-item>
                <v-tab-item value="tab-iesp">
                  <v-card tile>
                    <v-subheader>
                      Fórmula ANEEL para cálculo do IESP ::
                      {{ iqBdgdSiglas['IESP'] }}
                    </v-subheader>
                    <v-divider />
                    <v-card-text>
                      <formula-iesp
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :iesp="notas.iesp"
                        :itop="notas.itop"
                        :ipos="notas.ipos"
                      />
                    </v-card-text>
                  </v-card>
                  <status-indicador-iesp
                    class="mt-5"
                    :iqBdgdSiglas="iqBdgdSiglas"
                    :bdgdVersao="bdgd"
                    :editavel="userIsAdmin"
                    @statusIndicadorAtualizado="getIqBdgdNotas"
                  />
                </v-tab-item>
                <v-tab-item value="tab-icb">
                  <v-card tile>
                    <v-subheader>
                      Fórmula ANEEL para cálculo do ICB ::
                      {{ iqBdgdSiglas['ICB'] }}
                    </v-subheader>
                    <v-divider />
                    <v-card-text>
                      <formula-icb
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :icb="notas.icb"
                        :imcp="notas.imcp"
                        :iacc="notas.iacc"
                      />
                    </v-card-text>
                  </v-card>
                  <tabela-icb-analitico
                    class="mb-5"
                    :iqBdgdSiglas="iqBdgdSiglas"
                    :bdgdVersao="bdgd"
                  />
                </v-tab-item>
                <v-tab-item value="tab-ieb">
                  <v-card tile>
                    <v-subheader>
                      Fórmula ANEEL para cálculo do IEB ::
                      {{ iqBdgdSiglas['IEB'] }}
                    </v-subheader>
                    <v-divider />
                    <v-card-text>
                      <formula-ieb
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :ieb="notas.ieb"
                      />
                    </v-card-text>
                  </v-card>
                  <tabela-ieb-analitico
                    class="mb-5"
                    :iqBdgdSiglas="iqBdgdSiglas"
                    :bdgdVersao="bdgd"
                  />
                </v-tab-item>
                <v-tab-item value="tab-iptb">
                  <v-card tile>
                    <v-subheader>
                      Fórmula ANEEL para cálculo do IPTB ::
                      {{ iqBdgdSiglas['IPTB'] }}
                    </v-subheader>
                    <v-divider />
                    <v-card-text>
                      <formula-iptb
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :iptb="notas.iptb"
                      />
                    </v-card-text>
                  </v-card>
                  <tabela-iptb-analitico
                    class="mb-5"
                    :iqBdgdSiglas="iqBdgdSiglas"
                    :bdgdVersao="bdgd"
                  />
                </v-tab-item>
                <v-tab-item value="tab-icp">
                  <v-card tile>
                    <v-subheader>
                      Fórmula ANEEL para cálculo do ICP ::
                      {{ iqBdgdSiglas['ICP'] }}
                    </v-subheader>
                    <v-divider />
                    <v-card-text>
                      <formula-icp
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :icp="notas.icp"
                        :ient="notas.ient"
                        :iana="notas.iana"
                        :iprot="notas.iprot"
                      />
                    </v-card-text>
                  </v-card>
                  <v-tabs
                    centered
                    v-model="tabsLevel2"
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                      href="#tab-ient"
                      class="primary--text"
                    >
                      IENT
                    </v-tab>
                    <v-tab
                      href="#tab-iana"
                      class="primary--text"
                    >
                      IANA
                    </v-tab>
                    <v-tab
                      href="#tab-iprot"
                      class="primary--text"
                    >
                      IPROT
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabsLevel2">
                    <v-tab-item value="tab-ient">
                      <status-indicador-icp
                        class="mt-5"
                        indicador="IENT"
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :bdgdVersao="bdgd"
                        :editavel="userIsAdmin"
                        @statusIndicadorAtualizado="getIqBdgdNotas"
                      />
                    </v-tab-item>
                    <v-tab-item value="tab-iana">
                      <status-indicador-icp
                        class="mt-5"
                        indicador="IANA"
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :bdgdVersao="bdgd"
                        :editavel="userIsAdmin"
                        @statusIndicadorAtualizado="getIqBdgdNotas"
                      />
                    </v-tab-item>
                    <v-tab-item value="tab-iprot">
                      <status-indicador-icp
                        class="mt-5"
                        indicador="IPROT"
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :bdgdVersao="bdgd"
                        :editavel="userIsAdmin"
                        @statusIndicadorAtualizado="getIqBdgdNotas"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-tab-item>
                <v-tab-item value="tab-ico">
                  <v-card tile>
                    <v-subheader>
                      Fórmula ANEEL para cálculo do ICO ::
                      {{ iqBdgdSiglas['ICO'] }}
                    </v-subheader>
                    <v-divider />
                    <v-card-text>
                      <formula-ico
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :ico="notas.ico"
                        :icesc="notas.icesc"
                        :iccpl="notas.iccpl"
                      />
                    </v-card-text>
                  </v-card>
                  <v-tabs
                    centered
                    v-model="tabsLevel3"
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                      href="#tab-icesc"
                      class="primary--text"
                    >
                      ICESC
                    </v-tab>
                    <v-tab
                      href="#tab-iccpl"
                      class="primary--text"
                    >
                      ICCPL
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabsLevel3">
                    <v-tab-item value="tab-icesc">
                      <status-indicador-ico
                        class="mt-5"
                        indicador="ICESC"
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :bdgdVersao="bdgd"
                        :editavel="userIsAdmin"
                        @statusIndicadorAtualizado="getIqBdgdNotas"
                      />
                    </v-tab-item>
                    <v-tab-item value="tab-iccpl">
                      <status-indicador-ico
                        class="mt-5"
                        indicador="ICCPL"
                        :iqBdgdSiglas="iqBdgdSiglas"
                        :bdgdVersao="bdgd"
                        :editavel="userIsAdmin"
                        @statusIndicadorAtualizado="getIqBdgdNotas"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import IqBdgdService from '@/services/IqBdgdService';
import BdgdVersionsService from '@/services/BdgdVersionsService';
import StatusIndicadorIco from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/StatusIndicadorIco';
import StatusIndicadorIcp from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/StatusIndicadorIcp';
import StatusIndicadorIesp from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/StatusIndicadorIesp';
import FormulaIqbdgd from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIqbdgd';
import FormulaConsist from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaConsist';
import FormulaIcb from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIcb';
import FormulaIco from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIco';
import FormulaIcp from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIcp';
import FormulaIdb from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIdb';
import FormulaIesp from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIesp';
import FormulaIptb from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIptb';
import FormulaIeb from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/formulas/FormulaIeb';
import TabelaIcbAnalitico from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/tabelas/TabelaIcbAnalitico';
import TabelaIdbAnalitico from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/tabelas/TabelaIdbAnalitico';
import TabelaIptbAnalitico from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/tabelas/TabelaIptbAnalitico';
import TabelaIebAnalitico from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/tabelas/TabelaIebAnalitico';
import iqBdgdMixins from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/iqBdgdMixins';

export default {
  name: 'PainelIqBdgd',
  mixins: [iqBdgdMixins],
  components: {
    StatusIndicadorIco,
    StatusIndicadorIcp,
    StatusIndicadorIesp,
    FormulaIqbdgd,
    FormulaConsist,
    FormulaIcb,
    FormulaIco,
    FormulaIcp,
    FormulaIdb,
    FormulaIesp,
    FormulaIptb,
    FormulaIeb,
    TabelaIcbAnalitico,
    TabelaIdbAnalitico,
    TabelaIptbAnalitico,
    TabelaIebAnalitico
  },
  data: () => ({
    tabsLevel1: null,
    tabsLevel2: null,
    tabsLevel3: null,
    userIsAdmin: false,
    loadingBdgd: false,
    loadingIqBdgdSiglas: false,
    loadingIqBdgdNotas: false,
    bdgd: {
      id: 0,
      data_registro: null,
      versao: 1,
      ordinaria: false
    },
    iqBdgdSiglas: {},
    notas: {
      iqbdgd: 0,
      consist: 0,
      icp: 0,

      idb: 0,
      iesp: 0,
      icb: 0,
      ico: 0,
      ieb: 0,
      iptb: 0,

      ivb: 0,
      irlc: 0,
      iconj: 0,
      imun: 0,
      iene: 0,
      iext: 0,
      isev: 0,
      idda: 0,

      itop: 0,
      ipos: 0,

      imcp: 0,
      iacc: 0,

      icesc: 0,
      iccpl: 0,

      ient: 0,
      iana: 0,
      iprot: 0
    }
  }),
  mounted() {
    this.getUserIsAdmin();
    this.getBdgd();
    this.getIqBdgdSiglas();
    this.getIqBdgdNotas();
  },
  computed: {
    bdgdVersaoId() {
      return +this.$route.params.id;
    }
  },
  methods: {
    getUserIsAdmin() {
      AuthService.userIsAdmin().then(
        (userIsAdmin) => (this.userIsAdmin = userIsAdmin)
      );
    },
    getBdgd() {
      if (!this.bdgdVersaoId) return false;
      this.loadingBdgd = true;
      BdgdVersionsService.getById(this.bdgdVersaoId)
        .then((response) => (this.bdgd = response))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar os dados da versão da BDGD selecionada.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingBdgd = false));
    },
    getIqBdgdSiglas() {
      this.loadingIqBdgdSiglas = true;
      IqBdgdService.getSiglas()
        .then((response) => (this.iqBdgdSiglas = response.data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar as siglas do IQ_BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingIqBdgdSiglas = false));
    },
    getIqBdgdNotas() {
      if (!this.bdgdVersaoId) return false;
      this.loadingIqBdgdNotas = true;
      IqBdgdService.getNotas(this.bdgdVersaoId)
        .then((response) => {
          this.notas =
            response.data && response.data[0] ? response.data[0] : {};
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar as notas IQ_BDGD da versão da BDGD selecionada.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingIqBdgdNotas = false));
    }
  }
};
</script>

<style scoped>
.texto-grande {
  font-size: 80px;
}
</style>
