<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(icb)"
      :title="`ICB: ${iqBdgdSiglas['ICB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(icb)"
        class="darken-4"
      >
        {{ getNotaFormatada(icb) | parseNumberToFloatBR }}
      </v-avatar>
      ICB
    </v-chip>
    = MÍN (
    <v-chip
      class="mr-2"
      :color="getCorPorNota(imcp)"
      :title="`IMCP: ${iqBdgdSiglas['IMCP']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(imcp)"
        class="darken-4"
      >
        {{ getNotaFormatada(imcp) | parseNumberToFloatBR }}
      </v-avatar>
      IMCP
    </v-chip>
    ;
    <v-chip
      class="ml-2"
      :color="getCorPorNota(iacc)"
      :title="`IACC: ${iqBdgdSiglas['IACC']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iacc)"
        class="darken-4"
      >
        {{ getNotaFormatada(iacc) | parseNumberToFloatBR }}
      </v-avatar>
      IACC
    </v-chip>
    )
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaICB',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    icb: {
      default: 0
    },
    imcp: {
      default: 0
    },
    iacc: {
      default: 0
    }
  }
};
</script>
