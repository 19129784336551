var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
    {
      text: 'Etapa NVAL',
      value: 'etapa_nval'
    },
    {
      text: 'Teste SAMP',
      value: 'teste_samp'
    },
    {
      text: 'Erros Críticos',
      value: 'erros_criticos',
      align: 'end'
    },
    {
      text: 'Não Críticos',
      value: 'erros_nao_criticos',
      align: 'end'
    },
    {
      text: 'Exceções',
      value: 'excecoes',
      align: 'end'
    },
    {
      text: 'Análises Futuras',
      value: 'analises_futuras',
      align: 'end'
    },
    {
      text: 'Indicador',
      value: 'indicador',
      align: 'end'
    },
    {
      text: 'Nota',
      value: 'nota',
      align: 'center'
    }
  ],"items":_vm.iebAnalitico,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.etapa_nval",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" Etapa "+_vm._s(_vm._f("addLeadingZero")(item.etapa_nval))+" - "+_vm._s(item.sigla_teste)+" ")])]}},{key:"item.teste_samp",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(item.teste_samp)+" ")])]}},{key:"item.erros_criticos",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.erros_criticos))+" ")])]}},{key:"item.erros_nao_criticos",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.erros_nao_criticos))+" ")])]}},{key:"item.excecoes",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.excecoes))+" ")])]}},{key:"item.analises_futuras",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.analises_futuras))+" ")])]}},{key:"item.indicador",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(item.indicador)+" ")])]}},{key:"item.nota",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getCorPorNota(item.nota) })},[_vm._v(" "+_vm._s(item.nota)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }