export default {
  methods: {
    getCorPorNota(nota) {
      if (nota <= 1) return 'red';
      if (nota <= 3) return 'orange';
      if (nota <= 9) return 'green';
      return 'grey';
    },
    getNotaFormatada(nota) {
      return nota === 'NA' ? 0 : Number(nota).toFixed(2);
    }
  }
};