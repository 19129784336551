<template>
  <div>
    <v-row>
      <v-col cols="12">
        <tabela-iesp-analitico
          class="mb-5"
          :iqBdgdSiglas="iqBdgdSiglas"
          :bdgdVersao="bdgdVersao"
          :editavel="editavel"
          @statusIndicadorAtualizado="statusIndicadorAtualizado"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <status-indicador-ipos-timeline
          ref="timelineIndicadorIpos"
          :bdgdVersao="bdgdVersao"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TabelaIespAnalitico from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/tabelas/TabelaIespAnalitico';
import StatusIndicadorIposTimeline from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/StatusIndicadorIposTimeline';

export default {
  name: 'StatusIndicadorIesp',
  components: {
    TabelaIespAnalitico,
    StatusIndicadorIposTimeline
  },
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    editavel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    statusIndicadorAtualizado(data) {
      const refTimeline = this.$refs['timelineIndicadorIpos'];
      refTimeline.getHistoricoNumeroErros();
      this.$emit('statusIndicadorAtualizado', data);
    }
  }
};
</script>
