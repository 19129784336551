<template>
  <v-container fluid>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-timeline
        dense
        v-if="historico.length"
      >
        <v-timeline-item
          v-for="(alteracao, i) in historico"
          :key="i"
          color="cyan"
          small
        >
          <v-card class="elevation-2">
            <v-card-title class="text-h5">
              Alteração feita por {{ alteracao.usuario_alteracao }} em
              {{ alteracao.data_hora_alteracao | parseToDateTimeBR }}
            </v-card-title>
            <v-divider />
            <v-card-text>
              Número de erros do tipo "{{ alteracao.tipo_erro }}" alterado para
              o valor {{ alteracao.numero_erros | parseNumberToIntegerBR }}.
              <div v-if="alteracao.observacao">
                <v-divider class="mt-4 mb-4" />
                {{ alteracao.observacao }}
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
  </v-container>
</template>

<script>
import IqBdgdService from '@/services/IqBdgdService';

export default {
  name: 'StatusIndicadorIposTimeline',
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    historico: []
  }),
  mounted() {
    this.getHistoricoNumeroErros();
  },
  methods: {
    getHistoricoNumeroErros() {
      if (!(this.bdgdVersao && this.bdgdVersao.id)) return false;
      this.loading = true;
      IqBdgdService.getHistoricoNumeroErrosIpos(this.bdgdVersao.id)
        .then((response) => {
          this.historico = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o histórico de alterações de erros de IPOS.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getHistoricoNumeroErros();
    }
  }
};
</script>
