<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(idb)"
      :title="`IDB: ${iqBdgdSiglas['IDB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(idb)"
        class="darken-4"
      >
        {{ getNotaFormatada(idb) | parseNumberToFloatBR }}
      </v-avatar>
      IDB
    </v-chip>
    = MÍN (
    <v-chip
      class="mr-2"
      :color="getCorPorNota(ivb)"
      :title="`IVP: ${iqBdgdSiglas['IVB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(ivb)"
        class="darken-4"
      >
        {{ getNotaFormatada(ivb) | parseNumberToFloatBR }}
      </v-avatar>
      IVB
    </v-chip>
    ;
    <v-chip
      class="ml-2 mr-2"
      :color="getCorPorNota(irlc)"
      :title="`IRLC: ${iqBdgdSiglas['IRLC']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(irlc)"
        class="darken-4"
      >
        {{ getNotaFormatada(irlc) | parseNumberToFloatBR }}
      </v-avatar>
      IRLC
    </v-chip>
    ;
    <v-chip
      class="ml-2 mr-2"
      :color="getCorPorNota(iconj)"
      :title="`ICONJ: ${iqBdgdSiglas['ICONJ']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iconj)"
        class="darken-4"
      >
        {{ getNotaFormatada(iconj) | parseNumberToFloatBR }}
      </v-avatar>
      ICONJ
    </v-chip>
    ;
    <v-chip
      class="ml-2 mr-2"
      :color="getCorPorNota(imun)"
      :title="`IMUN: ${iqBdgdSiglas['IMUN']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(imun)"
        class="darken-4"
      >
        {{ getNotaFormatada(imun) | parseNumberToFloatBR }}
      </v-avatar>
      IMUN
    </v-chip>
    ;
    <v-chip
      class="ml-2 mr-2"
      :color="getCorPorNota(iene)"
      :title="`IENE: ${iqBdgdSiglas['IENE']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iene)"
        class="darken-4"
      >
        {{ getNotaFormatada(iene) | parseNumberToFloatBR }}
      </v-avatar>
      IENE
    </v-chip>
    ;
    <v-chip
      class="ml-2 mr-2"
      :color="getCorPorNota(iext)"
      :title="`IEXT: ${iqBdgdSiglas['IEXT']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iext)"
        class="darken-4"
      >
        {{ getNotaFormatada(iext) | parseNumberToFloatBR }}
      </v-avatar>
      IEXT
    </v-chip>
    ;
    <v-chip
      class="ml-2 mr-2"
      :color="getCorPorNota(isev)"
      :title="`ISEV: ${iqBdgdSiglas['ISEV']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(isev)"
        class="darken-4"
      >
        {{ getNotaFormatada(isev) | parseNumberToFloatBR }}
      </v-avatar>
      ISEV
    </v-chip>
    ;
    <v-chip
      class="ml-2"
      :color="getCorPorNota(idda)"
      :title="`IDDA: ${iqBdgdSiglas['IDDA']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(idda)"
        class="darken-4"
      >
        {{ getNotaFormatada(idda) | parseNumberToFloatBR }}
      </v-avatar>
      IDDA
    </v-chip>
    )
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaIDB',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    idb: {
      default: 0
    },
    ivb: {
      default: 0
    },
    irlc: {
      default: 0
    },
    iconj: {
      default: 0
    },
    imun: {
      default: 0
    },
    iene: {
      default: 0
    },
    iext: {
      default: 0
    },
    isev: {
      default: 0
    },
    idda: {
      default: 0
    }
  }
};
</script>
