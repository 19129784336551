<template>
  <v-data-table
    :headers="[
      {
        text: 'Etapa NVAL',
        value: 'etapa_nval'
      },
      {
        text: 'Erros Críticos',
        value: 'erros_criticos',
        align: 'end'
      },
      {
        text: 'Não Críticos',
        value: 'erros_nao_criticos',
        align: 'end'
      },
      {
        text: 'Exceções',
        value: 'excecoes',
        align: 'end'
      },
      {
        text: 'Análises Futuras',
        value: 'analises_futuras',
        align: 'end'
      },
      {
        text: 'Indicador',
        value: 'indicador',
        align: 'end'
      },
      {
        text: 'Nota',
        value: 'nota',
        align: 'center'
      }
    ]"
    :items="iespAnalitico"
    :loading="loading"
    :hide-default-header="loading"
    class="elevation-1"
    hide-default-footer
    disable-pagination
  >
    <template v-slot:[`item.etapa_nval`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        Etapa {{ item.etapa_nval | addLeadingZero }} - {{ item.sigla_teste }}
      </span>
    </template>
    <template v-slot:[`item.erros_criticos`]="{ item }">
      <span
        v-if="editavel && item.sigla_teste === 'IPOS'"
        :style="{ color: getCorPorNota(item.nota) }"
      >
        <v-edit-dialog
          large
          cancel-text="Cancelar"
          save-text="Salvar"
          @open="valorAtualizado = item.erros_criticos"
          @close="valorAtualizado = null"
          @save="() => salvarNumeroErros('ERRO_CRITICO')"
        >
          <div>{{ item.erros_criticos | parseNumberToIntegerBR }}</div>
          <template v-slot:input>
            <div class="mt-4 mb-4 text-h6">Erros Críticos</div>
            <v-text-field
              label="Atualizar valor"
              v-model="valorAtualizado"
              type="number"
              :step="1"
              :min="0"
              autofocus
            />
            <v-textarea
              label="Observação"
              v-model="observacao"
              rows="2"
              no-resize
              maxlength="255"
            />
          </template>
        </v-edit-dialog>
      </span>
      <span
        v-else
        :style="{ color: getCorPorNota(item.nota) }"
      >
        {{ item.erros_criticos | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.erros_nao_criticos`]="{ item }">
      <span
        v-if="editavel && item.sigla_teste === 'IPOS'"
        :style="{ color: getCorPorNota(item.nota) }"
      >
        <v-edit-dialog
          large
          cancel-text="Cancelar"
          save-text="Salvar"
          @open="valorAtualizado = item.erros_nao_criticos"
          @close="valorAtualizado = null"
          @save="() => salvarNumeroErros('ERRO_NAO_CRITICO')"
        >
          <div>{{ item.erros_nao_criticos | parseNumberToIntegerBR }}</div>
          <template v-slot:input>
            <div class="mt-4 mb-4 text-h6">Não Críticos</div>
            <v-text-field
              label="Atualizar valor"
              v-model="valorAtualizado"
              type="number"
              :step="1"
              :min="0"
              autofocus
            />
            <v-textarea
              label="Observação"
              v-model="observacao"
              rows="2"
              no-resize
              maxlength="255"
            />
          </template>
        </v-edit-dialog>
      </span>
      <span
        v-else
        :style="{ color: getCorPorNota(item.nota) }"
      >
        {{ item.erros_nao_criticos | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.excecoes`]="{ item }">
      <span
        v-if="editavel && item.sigla_teste === 'IPOS'"
        :style="{ color: getCorPorNota(item.nota) }"
      >
        <v-edit-dialog
          large
          cancel-text="Cancelar"
          save-text="Salvar"
          @open="valorAtualizado = item.excecoes"
          @close="valorAtualizado = null"
          @save="() => salvarNumeroErros('EXCECAO')"
        >
          <div>{{ item.excecoes | parseNumberToIntegerBR }}</div>
          <template v-slot:input>
            <div class="mt-4 mb-4 text-h6">Exceções</div>
            <v-text-field
              label="Atualizar valor"
              v-model="valorAtualizado"
              type="number"
              :step="1"
              :min="0"
              autofocus
            />
            <v-textarea
              label="Observação"
              v-model="observacao"
              rows="2"
              no-resize
              maxlength="255"
            />
          </template>
        </v-edit-dialog>
      </span>
      <span
        v-else
        :style="{ color: getCorPorNota(item.nota) }"
      >
        {{ item.excecoes | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.analises_futuras`]="{ item }">
      <span
        v-if="editavel && item.sigla_teste === 'IPOS'"
        :style="{ color: getCorPorNota(item.nota) }"
      >
        <v-edit-dialog
          large
          cancel-text="Cancelar"
          save-text="Salvar"
          @open="valorAtualizado = item.analises_futuras"
          @close="valorAtualizado = null"
          @save="() => salvarNumeroErros('ANALISE_FUTURA')"
        >
          <div>{{ item.analises_futuras | parseNumberToIntegerBR }}</div>
          <template v-slot:input>
            <div class="mt-4 mb-4 text-h6">Análises Futuras</div>
            <v-text-field
              label="Atualizar valor"
              v-model="valorAtualizado"
              type="number"
              :step="1"
              :min="0"
              autofocus
            />
            <v-textarea
              label="Observação"
              v-model="observacao"
              rows="2"
              no-resize
              maxlength="255"
            />
          </template>
        </v-edit-dialog>
      </span>
      <span
        v-else
        :style="{ color: getCorPorNota(item.nota) }"
      >
        {{ item.analises_futuras | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.indicador`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.indicador }}
      </span>
    </template>
    <template v-slot:[`item.nota`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.nota }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import IqBdgdService from '@/services/IqBdgdService';
import iqBdgdMixins from '../iqBdgdMixins';

export default {
  name: 'TabelaIespAnalitico',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    editavel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    iespAnalitico: [],
    valorAtualizado: 0,
    observacao: null
  }),
  mounted() {
    this.getIespAnalitico();
  },
  methods: {
    getIespAnalitico() {
      if (!(this.bdgdVersao && this.bdgdVersao.id)) return false;
      this.loading = true;
      IqBdgdService.getIespAnalitico(this.bdgdVersao.id)
        .then((response) => {
          this.iespAnalitico = response.data;
          const ipos = this.iespAnalitico.filter(
            (linha) => linha.indicador === 'IPOS'
          );
          if (!ipos.length) {
            this.adicionaLinhaIpos();
          }
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar os dados analíticos do IESP.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },
    adicionaLinhaIpos() {
      let linhaIpos = { ...this.iespAnalitico[0] };
      linhaIpos.indicador = 'IPOS';
      linhaIpos.sigla_teste = 'IPOS';
      linhaIpos.etapa_nval = 18;
      this.iespAnalitico.push(linhaIpos);
    },
    salvarNumeroErros(tipoErro) {
      const data = {
        bdgd_versao_id: this.bdgdVersao.id,
        tipo_erro: tipoErro,
        valor: this.valorAtualizado,
        observacao: this.observacao
      };
      IqBdgdService.salvarNumeroErrosIpos(data)
        .then(() => {
          this.$toast.success('Número de erros atualizado com sucesso.', '', {
            position: 'topRight'
          });
          this.observacao = null;
          this.getIespAnalitico();
          this.$emit('statusIndicadorAtualizado', data);
        })
        .catch(() => {
          this.$toast.error('Erro ao atualizar o número de erros.', '', {
            position: 'topRight'
          });
        });
    }
  },
  watch: {
    bdgdVersao() {
      this.getIespAnalitico();
    }
  }
};
</script>
