<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(ieb)"
      :title="`IEB: ${iqBdgdSiglas['IEB']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(ieb)"
        class="darken-4"
      >
        {{ getNotaFormatada(ieb) | parseNumberToFloatBR }}
      </v-avatar>
      IEB
    </v-chip>
    = MIN (EED_SAMP)
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaIEB',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    ieb: {
      default: 0
    }
  }
};
</script>
