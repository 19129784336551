<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(icp)"
      :title="`ICP: ${iqBdgdSiglas['ICP']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(icp)"
        class="darken-4"
      >
        {{ getNotaFormatada(icp) | parseNumberToFloatBR }}
      </v-avatar>
      ICP
    </v-chip>
    = MÉDIA (
    <v-chip
      class="mr-2"
      :color="getCorPorNota(ient)"
      :title="`IENT: ${iqBdgdSiglas['IENT']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(ient)"
        class="darken-4"
      >
        {{ getNotaFormatada(ient) | parseNumberToFloatBR }}
      </v-avatar>
      IENT
    </v-chip>
    ;
    <v-chip
      class="ml-2 mr-2"
      :color="getCorPorNota(iana)"
      :title="`IANA: ${iqBdgdSiglas['IANA']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iana)"
        class="darken-4"
      >
        {{ getNotaFormatada(iana) | parseNumberToFloatBR }}
      </v-avatar>
      IANA
    </v-chip>
    ;
    <v-chip
      class="ml-2"
      :color="getCorPorNota(iprot)"
      :title="`IPROT: ${iqBdgdSiglas['IPROT']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iprot)"
        class="darken-4"
      >
        {{ getNotaFormatada(iprot) | parseNumberToFloatBR }}
      </v-avatar>
      IPROT
    </v-chip>
    )
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaICP',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    icp: {
      default: 0
    },
    ient: {
      default: 0
    },
    iana: {
      default: 0
    },
    iprot: {
      default: 0
    }
  }
};
</script>
