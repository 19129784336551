<template>
  <v-data-table
    :headers="[
      {
        text: 'Etapa NVAL',
        value: 'etapa_nval'
      },
      {
        text: 'Erros Críticos',
        value: 'erros_criticos',
        align: 'end'
      },
      {
        text: 'Não Críticos',
        value: 'erros_nao_criticos',
        align: 'end'
      },
      {
        text: 'Exceções',
        value: 'excecoes',
        align: 'end'
      },
      {
        text: 'Análises Futuras',
        value: 'analises_futuras',
        align: 'end'
      },
      {
        text: 'Indicador',
        value: 'indicador',
        align: 'end'
      },
      {
        text: 'Nota',
        value: 'nota',
        align: 'center'
      }
    ]"
    :items="idbAnalitico"
    :loading="loading"
    :hide-default-header="loading"
    class="elevation-1"
    hide-default-footer
    disable-pagination
  >
    <template v-slot:[`item.etapa_nval`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        Etapa {{ item.etapa_nval | addLeadingZero }} - {{ item.sigla_teste }}
      </span>
    </template>
    <template v-slot:[`item.erros_criticos`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.erros_criticos | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.erros_nao_criticos`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.erros_nao_criticos | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.excecoes`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.excecoes | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.analises_futuras`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.analises_futuras | parseNumberToIntegerBR }}
      </span>
    </template>
    <template v-slot:[`item.indicador`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.indicador }}
      </span>
    </template>
    <template v-slot:[`item.nota`]="{ item }">
      <span :style="{ color: getCorPorNota(item.nota) }">
        {{ item.nota }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import IqBdgdService from '@/services/IqBdgdService';
import iqBdgdMixins from '../iqBdgdMixins';

export default {
  name: 'TabelaIdbAnalitico',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    idbAnalitico: []
  }),
  mounted() {
    this.getIdbAnalitico();
  },
  methods: {
    getIdbAnalitico() {
      if (!(this.bdgdVersao && this.bdgdVersao.id)) return false;
      this.loading = true;
      IqBdgdService.getIdbAnalitico(this.bdgdVersao.id)
        .then((response) => (this.idbAnalitico = response.data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar os dados analíticos do IDB.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getIdbAnalitico();
    }
  }
};
</script>
