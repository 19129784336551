<template>
  <v-container fluid>
    <v-progress-circular
      v-if="
        loadingStatusPossiveisPorIndicador || loadingStatusAtualPorIndicador
      "
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-row>
        <v-col cols="6">
          <v-data-table
            v-model="statusIndicador"
            :headers="headers"
            :items="statusPossiveisPorIndicador"
            :single-select="true"
            :show-select="editavel"
            :loading="loadingStatusPossiveisPorIndicador"
            :hide-default-header="loadingStatusPossiveisPorIndicador"
            class="elevation-1"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.descricao`]="{ item }">
              <span :style="{ color: getCorPorNota(item.nota) }">
                {{ item.descricao }}
              </span>
            </template>
            <template v-slot:[`item.nota`]="{ item }">
              <span :style="{ color: getCorPorNota(item.nota) }">
                {{ item.nota }}
              </span>
            </template>
          </v-data-table>
          <div v-if="editavel">
            <v-textarea
              label="Observação"
              v-model="observacao"
              rows="3"
              no-resize
              maxlength="255"
              class="mt-8"
              placeholder="Opcionalmente, informe um texto explicando o motivo da nova nota"
            />
          </div>
          <div
            v-if="editavel"
            class="d-flex justify-end"
          >
            <v-btn
              color="primary"
              min-width="100"
              @click="salvarStatusIndicador"
              :disabled="!statusIndicador.length"
              :loading="loadingSalvarStatusIndicador"
            >
              Salvar
            </v-btn>
          </div>
        </v-col>
        <v-col cols="6">
          <status-indicador-icp-timeline
            :ref="`timelineIndicador${indicador}`"
            :indicador="indicador"
            :bdgdVersao="bdgdVersao"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import IqBdgdService from '@/services/IqBdgdService';
import StatusIndicadorIcpTimeline from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/StatusIndicadorIcpTimeline';
import iqBdgdMixins from './iqBdgdMixins';

export default {
  name: 'StatusIndicadorIcp',
  mixins: [iqBdgdMixins],
  components: {
    StatusIndicadorIcpTimeline
  },
  props: {
    indicador: {
      type: String,
      required: true
    },
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    editavel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingStatusPossiveisPorIndicador: false,
    loadingStatusAtualPorIndicador: false,
    loadingSalvarStatusIndicador: false,
    statusPossiveisPorIndicador: [],
    statusIndicador: [],
    observacao: null
  }),
  mounted() {
    this.getStatusPossiveisPorIndicador();
  },
  computed: {
    headers() {
      const descricao = `${this.indicador} :: ${
        this.iqBdgdSiglas[this.indicador]
      }`;
      return [
        {
          text: descricao,
          value: 'descricao'
        },
        {
          text: 'NOTA',
          value: 'nota'
        }
      ];
    }
  },
  methods: {
    getStatusPossiveisPorIndicador() {
      this.loadingStatusPossiveisPorIndicador = true;
      IqBdgdService.getStatusPossiveisPorIndicador(this.indicador)
        .then((response) => {
          this.statusPossiveisPorIndicador = response.data;
          this.getStatusAtualPorIndicador();
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os status possíveis para o indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingStatusPossiveisPorIndicador = false));
    },
    getStatusAtualPorIndicador() {
      this.loadingStatusAtualPorIndicador = true;
      IqBdgdService.getStatusAtualPorIndicador(
        this.bdgdVersao.id,
        this.indicador
      )
        .then((response) => {
          const statusAtual =
            response.data && response.data[0] ? response.data[0] : null;
          if (statusAtual) {
            this.statusIndicador = this.statusPossiveisPorIndicador.filter(
              (status) => status.codigo === statusAtual.codigo_status
            );
          }
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar o status atual para o indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingStatusAtualPorIndicador = false));
    },
    salvarStatusIndicador() {
      this.loadingSalvarStatusIndicador = true;
      const data = {
        bdgd_versao_id: this.bdgdVersao.id,
        status_id: this.statusIndicador[0].id,
        observacao: this.observacao,
        indicador: this.indicador
      };
      IqBdgdService.salvarStatusIndicador(data)
        .then(() => {
          this.$toast.success(
            `Novo status para o indicador ${this.indicador} salvo com sucesso.`,
            '',
            {
              position: 'topRight'
            }
          );
          this.observacao = null;
          const refTimeline = this.$refs[`timelineIndicador${this.indicador}`];
          refTimeline.getHistoricoStatusPorIndicador();
          this.$emit('statusIndicadorAtualizado', {
            ...data,
            status_selecionado: this.statusIndicador
          });
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao salvar o novo status para o indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingSalvarStatusIndicador = false));
    }
  }
};
</script>
