<template>
  <v-container fluid>
    <v-chip
      :color="getCorPorNota(iqbdgd)"
      :title="`IQ_BDGD: ${iqBdgdSiglas['IQ_BDGD']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(iqbdgd)"
        class="darken-4"
      >
        {{ getNotaFormatada(iqbdgd) | parseNumberToFloatBR }}
      </v-avatar>
      IQ_BDGD
    </v-chip>
    = MÉDIA (
    <v-chip
      class="mr-2"
      :color="getCorPorNota(consist)"
      :title="`CONSIST: ${iqBdgdSiglas['CONSIST']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(consist)"
        class="darken-4"
      >
        {{ getNotaFormatada(consist) | parseNumberToFloatBR }}
      </v-avatar>
      CONSIST
    </v-chip>
    ;
    <v-chip
      class="ml-2"
      :color="getCorPorNota(icp)"
      :title="`ICP: ${iqBdgdSiglas['ICP']}`"
      text-color="white"
    >
      <v-avatar
        left
        :class="getCorPorNota(icp)"
        class="darken-4"
      >
        {{ getNotaFormatada(icp) | parseNumberToFloatBR }}
      </v-avatar>
      ICP
    </v-chip>
    )
  </v-container>
</template>

<script>
import iqBdgdMixins from '../iqBdgdMixins';
export default {
  name: 'FormulaIQBDGD',
  mixins: [iqBdgdMixins],
  props: {
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    iqbdgd: {
      default: 0
    },
    consist: {
      default: 0
    },
    icp: {
      default: 0
    }
  }
};
</script>
