<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            small
            title="Baixar dados ICO"
            outlined
            class="mb-4 mt-8 mr-4"
            @click="downloadIco()"
            :loading="loadingDownloadIco"
          >
            <v-icon
              small
              color="primary"
            >
              mdi-download
            </v-icon>
          </v-btn>
          <v-btn
            small
            title="Importar dados ICO"
            outlined
            class="mb-4 mt-8 mr-4"
            @click="dialogImportIco = !dialogImportIco"
          >
            <v-icon
              small
              color="primary"
            >
              mdi-file-upload
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="mr-4 mt-4 mb-4 break-search"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />
        </v-row>
  
        <v-data-table
          :headers="headers"
          :items="icoAnalitico"
          :loading="loadingIcoAnalitico"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`item.variavel_descricao`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota) }">
              {{ item.variavel_descricao }}
            </span>
          </template>
          <template v-slot:[`item.variacao_ano2`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano2) }">
              {{ item.variacao_ano2 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.nota_ano2`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano2) }">
              {{ item.nota_ano2 }}
            </span>
          </template>
          <template v-slot:[`item.variacao_ano3`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano3) }">
              {{ item.variacao_ano3 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.nota_ano3`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano3) }">
              {{ item.nota_ano3 }}
            </span>
          </template>
          <template v-slot:[`item.variacao_ano4`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano4) }">
              {{ item.variacao_ano4 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.nota_ano4`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota_ano4) }">
              {{ item.nota_ano4 }}
            </span>
          </template>
          <template v-slot:[`item.nota`]="{ item }">
            <span :style="{ color: getCorPorNota(item.nota) }">
              {{ item.nota }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano1`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano1"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno1)"
              >
                <div>{{ item.valor_bdgd_ano1 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno1 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano1 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano2`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano2"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno2)"
              >
                <div>{{ item.valor_bdgd_ano2 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno2 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano2 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano3`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano3"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno3)"
              >
                <div>{{ item.valor_bdgd_ano3 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno3 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano3 | parseNumberToFloatBR }}
            </span>
          </template>
          <template v-slot:[`item.valor_bdgd_ano4`]="{ item }">
            <span v-if="editavel">
              <v-edit-dialog
                large
                cancel-text="Cancelar"
                save-text="Salvar"
                @open="valorAtualizado = item.valor_bdgd_ano4"
                @close="valorAtualizado = null"
                @save="() => salvarVariavel(item.variavel_id, bdgdAno4)"
              >
                <div>{{ item.valor_bdgd_ano4 | parseNumberToFloatBR }}</div>
                <template v-slot:input>
                  <div class="mt-4 mb-4 text-h6">
                    BDGD {{ bdgdAno4 }} :: {{ item.variavel_descricao }}
                  </div>
                  <v-text-field
                    label="Atualizar valor"
                    v-model="valorAtualizado"
                    type="number"
                    :step="0.01"
                    autofocus
                  />
                  <v-textarea
                    label="Observação"
                    v-model="observacao"
                    rows="2"
                    no-resize
                    maxlength="255"
                  />
                </template>
              </v-edit-dialog>
            </span>
            <span v-else>
              {{ item.valor_bdgd_ano4 | parseNumberToFloatBR }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-circular
          v-if="loadingIcoAnalitico"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <div v-else>
          <status-indicador-ico-timeline
            :ref="`timelineIndicador${indicador}`"
            :indicador="indicador"
            :bdgdVersao="bdgdVersao"
          />
        </div>
      </v-col>
    </v-row>
    
    <v-dialog
      v-model="dialogImportIco"
      scrollable
      max-width="900px"
    >
      <v-card>
        <v-card-title class="justify-center display-2 font-weight-light pt-5">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <div class="text-center display-1 font-weight-normal mb-3 mt-3">
            Por favor, envie o XLSX a ser carregado no banco de dados
          </div>

          <v-container>

            <v-row class="mx-auto">
              <v-col cols="12">
                <v-alert dense outlined type="info" color="blue">
                  <div>
                    <a href="javascript:void(0)" @click="downloadIco()">Clique aqui</a> para baixar o modelo para a importação.
                  </div>
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <upload-files
                  id="fs-upload-importacao-dados"
                  :allowedFiles="allowedFiles"
                  :allowedEncoding="allowedEncoding"
                  @file:uploaded="handleFileUploaded"
                />
              </v-col>
            </v-row>    

            <v-row class="mx-auto" v-if="lastUploadedFile">
              <v-col cols="12">
                <v-text-field
                  label="Arquivo enviado"
                  outlined
                  readonly
                  v-model="lastUploadedFile.arquivo"
                />
              </v-col>
            </v-row>

            <v-card-actions style="text-align: right !important; display: block !important">
              <v-btn
                color="primary"
                :loading="loadingImportIco"
                @click="saveImport()"
              >
                Salvar        
              </v-btn>
            </v-card-actions>
          
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import iqBdgdMixins from './iqBdgdMixins';
import IqBdgdService from '@/services/IqBdgdService';
import UploadFiles from '@/components/general/UploadFiles/Index.vue';
import StatusIndicadorIcoTimeline from '@/components/geracao-bdgd/validacoes-bdgd/iq-bdgd/StatusIndicadorIcoTimeline';

export default {
  name: 'StatusIndicadorIco',
  mixins: [iqBdgdMixins],
  components: {
    StatusIndicadorIcoTimeline,
    UploadFiles
  },
  props: {
    indicador: {
      type: String,
      required: true
    },
    iqBdgdSiglas: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    editavel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogImportIco:false,
    loadingImportIco:false,
    loadingDownloadIco: false,
    loadingIcoAnalitico: false,
    loadingSalvarVariavel: false,
    search: null,
    icoAnalitico: [],
    valorAtualizado: null,
    observacao: null,

    //Parâmetros arquivo
    allowedFiles: ['XLSX'],
    allowedEncoding: ['UTF-8'],
    uploadedFiles: []
  }),
  mounted() {
    this.getIcoAnalitico();
  },
  computed: {
    headers() {
      const descricao = `${this.indicador} :: ${
        this.iqBdgdSiglas[this.indicador]
      }`;
      return [
        {
          text: descricao,
          value: 'variavel_descricao'
        },
        {
          text: this.bdgdAno1,
          value: 'valor_bdgd_ano1',
          align: 'end'
        },
        {
          text: this.bdgdAno2,
          value: 'valor_bdgd_ano2',
          align: 'end'
        },
        {
          text: 'Variação (%)',
          value: 'variacao_ano2',
          align: 'end'
        },
        {
          text: 'Nota',
          value: 'nota_ano2',
          align: 'end'
        },
        {
          text: this.bdgdAno3,
          value: 'valor_bdgd_ano3',
          align: 'end'
        },
        {
          text: 'Variação (%)',
          value: 'variacao_ano3',
          align: 'end'
        },
        {
          text: 'Nota',
          value: 'nota_ano3',
          align: 'end'
        },
        {
          text: this.bdgdAno4,
          value: 'valor_bdgd_ano4',
          align: 'end'
        },
        {
          text: 'Variação (%)',
          value: 'variacao_ano4',
          align: 'end'
        },
        {
          text: 'Nota',
          value: 'nota_ano4',
          align: 'end'
        },
        {
          text: 'Nota Final',
          value: 'nota',
          align: 'center'
        }
      ];
    },
    bdgdAno1() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano1']
        : 0;
    },
    bdgdAno2() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano2']
        : 0;
    },
    bdgdAno3() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano3']
        : 0;
    },
    bdgdAno4() {
      return this.icoAnalitico.length > 0
        ? this.icoAnalitico[0]['bdgd_ano4']
        : 0;
    },
    title() {
      return `Importar dados do indicador :: ${this.indicador}`
    },
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    }
  },
  methods: {
    getIcoAnalitico() {
      if (!(this.bdgdVersao && this.bdgdVersao.id)) return false;
      this.loadingIcoAnalitico = true;
      IqBdgdService.getIcoAnalitico(this.bdgdVersao.id, this.indicador)
        .then((response) => (this.icoAnalitico = response.data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados analíticos do indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingIcoAnalitico = false));
    },
    salvarVariavel(variavelId, bdgdAno) {
      this.loadingSalvarVariavel = true;
      const data = {
        indicador: this.indicador,
        variavel_id: variavelId,
        ano_bdgd: bdgdAno,
        valor: this.valorAtualizado,
        observacao: this.observacao
      };
      IqBdgdService.salvarVariavelIco(data)
        .then(() => {
          this.$toast.success(
            `Novo valor para o indicador ${this.indicador} salvo com sucesso.`,
            '',
            {
              position: 'topRight'
            }
          );
          this.observacao = null;
          this.getIcoAnalitico();
          const refTimeline = this.$refs[`timelineIndicador${this.indicador}`];
          refTimeline.getHistoricoStatusPorIndicador();
          this.$emit('statusIndicadorAtualizado', data);
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao salvar o novo valor para o indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingSalvarVariavel = false));
    },
    downloadIco() {
      this.loadingDownloadIco = true;
      IqBdgdService.downloadIco(this.bdgdVersao.id, this.indicador)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `iq-bdgd-${this.bdgdVersao.id}-ico-${this.indicador.toLowerCase()}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error(`Falha no download dos dados do indicador ${this.indicador}.`, '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingDownloadIco = false));
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    },
    saveImport() {
      this.loadingImportIco = true;

      const dados = {        
        'indicador': this.indicador,
        'bdgdVersaoId': this.bdgdVersao.id,
        'arquivo': this.lastUploadedFile
      }

      IqBdgdService.importarIco(dados)
        .catch((error) => {
          this.$toast.error(
            `Erro ao importar os valores do indicador ${this.indicador}.`,
            '',
            {
              position: 'topRight'
            }
          );
          console.log('Error: ' + error);
        })
        .finally(() => {
          this.loadingImportIco = false;
          this.dialogImportIco = false;
          this.uploadedFiles = [];
          this.getIcoAnalitico();
        });
    }
  }
};
</script>
